.skleton{
    background-color: rgb(218, 218, 218);
}
.skleton.cover{
    width: 95%;
    height: 90%;
    border-radius: 5px;
}
.skleton.titel{
    /* width: 100%; */
    margin: 5px;
    height: 12px;
}
.skleton.sub1{
    width: 90%;
    border-radius: 4px;
    margin: 5px;
    height: 10px;
}
.skleton.sub2{
    width: 80%;
    border-radius: 4px;
    margin: 5px;
    height: 10px;
}
.skleton.sub2{
    width: 60%;
    border-radius: 4px;
    margin: 5px;
    height: 10px;
}
.skleton.btton{
    width: 150px;
    margin-left: 5px;
    margin-top: 10px;
    height: 20px;
}

/* animation */
.shimmer-wrapper {
    position: absolute;
    /* parent must be relative position & overflow hidden */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 1.5s infinite;
  }
  .shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-1deg);
    box-shadow: 0 0 30px 30px rgba(255,255,255,0.2);
  }
  .dark .shimmer {
    background: rgba(255,255,255,0.05);
    box-shadow: 0 0 30px 30px rgba(255,255,255,0.05);
  }
  
  @keyframes loading {
    0% { transform: translateX(-150%) }
    50% { transform: translateX(-60%) }
    100% { transform: translateX(150%) }
  }
