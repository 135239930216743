.searchscreen{
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
}
.searchheader{
    display: flex;
    height: 7%;
    align-items: center;
    flex-direction: row;
}
.searchinput{
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    background-color: antiquewhite;
    position: relative;

}
.nodata{   
     display: flex;
     height: 70%;
     align-items: center;
     justify-content: center;  
     color: rgb(201, 196, 196); 
     text-align:center; 
     margin-left: 10px;
     margin-right: 10px;
     font-family: var(--font);
     letter-spacing: 1px;
}
.moreloading{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(59, 130, 236);
    font-size: 20px;
}
.moreloading span{
  margin-left: 10px;
}
.booklist{
    display: flex;
    height: 93%;
    overflow: scroll;
    scroll-behavior: smooth;
}
.searchboxfiled { 
    width: 100%; 
    height: 40px;
    padding-left: 5px;
    padding-right: 60px;
    display: inline-block; 
    border: 0px solid var(--bg2-color); 
    box-sizing: border-box; 
    border-radius: 5px;
    background-color: bisque;
    font-family: var(--font);
    letter-spacing: 1px;

} 
.searchicon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 40px;
    right: 0px;
    top: 0px;
    font-size: 20px;
    background-color: rgb(0, 110, 255);
    color: #fff;
}
.clearicon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    right: 40px;
    top: 0px;
    padding: 10px;
    font-size: 15px;
    color: #B1B0AF;

}
