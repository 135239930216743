b{
    font-weight: bold;
}
#toc a{
    color: #273746;
}
.descPage
{
    padding: 10px;
   
}
Button{
    font-family: var(--font);
}

.descImg
{
    text-align: center;
    width: 100%;
    height: 450px;
    /* margin:0 auto; */
    /* padding:10px 20px; */
    background-color: #EEFBF9;


}
.descTitle
{
    font-weight: 600;
    font-size: 18px;
    color: #9099e0;
    margin-top: 5px;
   
}
.descAuthor
{
    font-weight: 600;
    white-space: normal;
    font-size: 14px;
    margin-top: 7px;
    
}
.descPub
{
    color: #999999;
    font-weight: 600;
    font-size: 14px;
    margin-top: 0px;
    
}
.buttonList
{
    padding-right: 3px;
    padding-bottom: 10px;
}

.descHead
{
    
    font-weight: bold;
    /* margin-top: 20px; */
}

.description
{
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    text-align:justify
    
}
.btntext{
    color: #ffff;
}

.coverbg{
    background-repeat:no-repeat;
    background-size: cover;
    position: relative;
    height: 40%;
    width: 100%;
    filter: blur(3px);
    background-color: rgb(218, 218, 218);
    animation:example 4s infinite;

}
.dcover_cntiner{
    width: 80%;
    height: 100%;
    background-color: rgb(218, 218, 218);
    animation:example 10s infinite;
}
.dcover{
    max-width: 70%;
    background-color: #EEFBF9;
    
  

}


@keyframes example {
    0%   {background-color: rgb(221, 217, 217);}
    25%  {background-color: rgb(177, 177, 174);}
    50%  {background-color: rgb(213, 213, 216);}
    100% {background-color: rgb(168, 172, 168);}
  }