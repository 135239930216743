
.dashboard{
    margin-top: 10px;

    scroll-behavior: smooth;
}
.tabbarrcontiner{
    height:60px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:#fff;

}
.tabbarr{
    position:fixed;
    display: flex;
    width: 100%;
    height: 50px;
    bottom: 0px;
    align-items:center;
    justify-content: center;
    background-color:rgb(245, 182, 122);
    /* background-image: linear-gradient(180deg, rgb(245, 182, 122),rgba(247, 241, 241, 0),); */
    /* border-radius: "10px"; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    /* border-bottom: 1px solid rgba(202, 202, 202, 0.932);  */
   }
   .tabbar_itemm{
    display: flex;
    width: 30%;
    height: 40px;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;
    letter-spacing: 1px;
    background-color:rgb(245, 182, 122);
    font-family: var(--font);

   }
   .fooder{
       margin-top: 10px;
       display: flex;
       width: 100%;
       height: 100px;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       /* background-image: linear-gradient(to right, rgba(247, 241, 241, 0), rgb(245, 182, 122)); */

    }
    .fooder_continer{
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-image: linear-gradient(180deg,rgba(247, 241, 241, 0), rgb(245, 182, 122));
    }
    .counter{
        display: flex;
        width: 100%;
        background-color: #ffffff;
        padding: 5px;
    }
    .counter_title{
        display: flex;
        height: 30px;
        width: 100%;
        font-size: 18px;
        letter-spacing: 1px;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        color: black;
       margin-top: 10px;
       margin-bottom: 3px;
      padding-left: 3px; 
      border-left-style: solid ;
      border-left-color: #f67225;
      background-color: #ffffff;
    }
    .Carousel{
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 10px;
    }
   @media only screen and (min-width: 600px) {
    .tabbarrcontiner{
        display: none;
        }
        .fooder_continer{
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            background-image: linear-gradient(180deg,rgba(247, 241, 241, 0), rgb(245, 182, 122));
        }
       
   }
  

   @media only screen and (min-width: 768px) {
    .tabbarrcontiner{
       display: none;
       }
       .fooder_continer{
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background-image: linear-gradient(180deg,rgba(247, 241, 241, 0), rgb(245, 182, 122));
        /* background-image: linear-gradient(to right, rgba(247, 241, 241, 0), rgb(245, 182, 122)); */
    }
      
      
   }